import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import axios from "axios"
import settings from "../settings"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { v4 } from "uuid"
import ScrollToTop from "react-scroll-to-top"
import { Modal, ModalBody, ModalFooter } from "reactstrap"

import "./itinerary-view.scss"
import Seo from "../components/seo"
import Se from "simple-react-lightbox"

let ITIN_PAYMENT_FEATURE = false

function calcCrow(lat1, lon1, lat2, lon2) {
  let R = 6371 // km
  let dLat = toRad(lat2 - lat1)
  let dLon = toRad(lon2 - lon1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  let d = R * c
  return d
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180
}
function getNearbyCities(currentCityID, cities, latitude, longitude) {
  const result = cities.nodes.filter(function (city) {
    return (
      calcCrow(latitude, longitude, city.latitude, city.longitude) < 200 &&
      city.mysqlId !== currentCityID
    )
  })
  return result.slice(0, Math.min(6, result.length))
}

function getRelatedItineraries(currentItinerary, itineraries) {
  const result = itineraries
    .filter(iti => {
      if (iti.popular_collection !== null && currentItinerary !== null) {
        if (iti.popular_collection === currentItinerary.popular_collection) {
          return true
        }
      }
      if (iti.city_id === currentItinerary.city_id) {
        return true
      }
      if (iti.stateId === currentItinerary.stateId) {
        return true
      }
    })
    .filter(iti => {
      return iti.mysqlId !== currentItinerary.mysqlId
    })
  return result.slice(0, Math.min(14, result.length))
}




export default function ItineraryView({ data }) {
  const [showFullContent, setShowFullContent] = useState(true)

  // if(data.mysqlItineraryAllData.title==='Agra, Uttar Pradesh – 2 Nights & 3 Days Itinerary'){
    ITIN_PAYMENT_FEATURE = false;
    // console.log("check",showFullContent);
  // }
  const [confirmationMail, setConfirmationMail] = useState("")

  const loadScript = src => {
    return new Promise(resolve => {
      const script = document.createElement("script")
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }


  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js")
  })

  
useEffect(() => {    
  // const script = document.createElement('script');
  loadScript('https://www.booking.com/affiliate/prelanding_sdk');
  // script.async = true;
  // document.body.appendChild(script);        

  // return () => {      
  //   document.body.removeChild(script);  
  // }
});


useEffect(() => {      
  
  const onPageLoad = () =>{
    const script1 = document.createElement('script');
    script1.defer = true;
    script1.innerHTML = `(function () {
      var BookingAffiliateWidget = new Booking.AffiliateWidget({
      "iframeSettings": {
      "selector": "bookingAffiliateWidget_b7482b54-efa7-4f3c-8b85-7bb9bf8247a5",
      "responsive": true
      },
      "widgetSettings": {}
      });
        })();`  

    document.body.appendChild(script1);

    return () => {           
      document.body.removeChild(script1);
    }
  }
  
  if (document.readyState === 'complete') {
    onPageLoad();
  } else {
    window.addEventListener('load', onPageLoad);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener('load', onPageLoad);
  }
  
}, []);

  if (!(data && data.mysqlItineraryAllData)) {
    return <Layout>Not found</Layout>
  }
  

  const post = data.mysqlItineraryAllData
  const content = JSON.parse(post.content)
  const bannerImage = JSON.parse(content.primaryInfo.bannerImage)
  const nearestAirport = JSON.parse(content.primaryInfo.nearest_airport || "{}")
  const nearestRailhead = JSON.parse(
    content.primaryInfo.nearest_railhead || "{}"
  )
  const relatedItineraries = getRelatedItineraries(
    post,
    data.allMysqlItineraryAllData.nodes
  )

  const readMore = () => {
    document.querySelector(".overviewContent .readmore").style = "display:none;"
    let height = 0
    const a = setInterval(function () {
      height++
      document.querySelector(
        ".overviewContent .inner"
      ).style = `max-height:${height}%`
      if (height === 100) {
        clearInterval(a)
      }
    }, 5)
  }

  let itemsAfterDayWiseSection = false
  let numberOfDays = content.itineraryContent[1]?.schedule?.length

  return (
    <Layout>
      {ITIN_PAYMENT_FEATURE && showFullContent && confirmationMail && (
        <ModalComponent
          confirmationMail={confirmationMail}
          setConfirmationMail={setConfirmationMail}
        />
      )}
      <ScrollToTop smooth />
      <Seo
        title={post.title + "| iwant2explore.com | visa2explore"}
        description={post.meta_description}
        keywords={post.meta_keyword}
      />
      <div className="section">
        <Container fluid>
          <Container>
            <Row>
              <Col lg={8}>
                <Breadcrumb>
                  <Breadcrumb.Item href={post.zone_slug.toLowerCase()}>
                    {post.zone_title}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href={post.state_slug.toLowerCase()}>
                    {post.state_title}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href={post.city_slug.toLowerCase()}>
                    {post.city_title}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <h1>{post.title}</h1>
                <p className="subtitle">
                  {content.primaryInfo.destination_list}
                </p>
                <p className="publishDate">
                  <i>Published on {(post.createdAt + "").substring(0, 10)}</i>
                </p>

                <SimpleReactLightbox>
                  <SRLWrapper
                    options={{
                      buttons: { showDownloadButton: false },
                    }}
                  >
                    <a
                      href={
                        settings.IMAGE_URL + bannerImage.publicUrl + ".webp"
                      }
                    >
                      <img
                        src={
                          settings.IMAGE_URL + bannerImage.publicUrl + ".webp"
                        }
                        className="bannerImage"
                        alt={bannerImage.caption}
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
                <Caption image={bannerImage} />
                <div className="quickinfoSection">
                  <Row>
                    <Col lg={2} sm={6} xs={6} className="col-destination">
                      <span>Destination</span>
                      <p className="destination">
                        {content.primaryInfo.destination_type}
                      </p>
                    </Col>
                    <Col lg={4} sm={12} xs={12}>
                      <div className="airport">
                        <span>Airport</span>
                        <a href={nearestAirport?.url} target="_blank">
                          <p>{nearestAirport?.name}</p>
                        </a>
                      </div>
                    </Col>
                    <Col lg={3} sm={12} xs={12}>
                      <div className="rail">
                        <span>Railhead</span>
                        <a href={nearestRailhead?.url} target="_blank">
                          <p>{nearestRailhead?.name}</p>
                        </a>
                      </div>
                    </Col>
                    <Col lg={3} sm={12} xs={12} className={"col-bttv"}>
                      <span>Best time to visit</span>
                      <p>
                        {content.primaryInfo.bttv_from} to{" "}
                        {content.primaryInfo.bttv_to}
                      </p>
                    </Col>
                  </Row>
                </div>
                <Row className="overviewContent">
                  <div
                    className="inner collapseContent"
                    dangerouslySetInnerHTML={{
                      __html: content.primaryInfo.overview_content,
                    }}
                  ></div>
                  <div className="readmore">
                    <a onClick={readMore}>Read More</a>
                  </div>
                </Row>

                {content.itineraryContent.map((block, i) => {
                  if (block.type === "CONTENT") {
                    return (
                      <ContentBlock
                        key={"content_block_" + i}
                        data={block}
                        numberOfDays={numberOfDays}
                        showFullContent={showFullContent}
                        setShowFullContent={setShowFullContent}
                        confirmationMail={confirmationMail}
                        setConfirmationMail={setConfirmationMail}
                        itemsAfterDayWiseSection={itemsAfterDayWiseSection}
                      />
                    )
                  } else if (block.type === "DAYWISESCHEDULE") {
                    itemsAfterDayWiseSection = true
                    return (
                      <DaywiseSchedule
                        key={"schedule_block" + i}
                        data={block}
                        numberOfDays={numberOfDays}
                        showFullContent={showFullContent}
                        setShowFullContent={setShowFullContent}
                        confirmationMail={confirmationMail}
                        setConfirmationMail={setConfirmationMail}
                      />
                    )
                  } else if (block.type === "IMAGE") {
                    return <ImageBlock key={"image_block_" + i} data={block} />
                  }
                })}

                <Row className="engagementSection">
                  <Col lg={12}>
                    <h2>Still have more questions about this trip?</h2>
                  </Col>
                </Row>
                <Row>
                  <Comments contentID={post.mysqlId} />
                </Row>
              </Col>
              <Col lg={4}>
                <div className="widget">
                  {data.allMysqlCities && (
                    <NearbyDestinations
                      data={getNearbyCities(
                        data.mysqlItineraryAllData.city_id,
                        data.allMysqlCities,
                        data.mysqlItineraryAllData.latitude,
                        data.mysqlItineraryAllData.longitude
                      )}
                    />
                  )}
                  {relatedItineraries && (
                    <RelatedDestinations data={relatedItineraries} />
                  )}
                  
                  <div style={{ height: "480px !important" }} id="bookingAffiliateWidget_b7482b54-efa7-4f3c-8b85-7bb9bf8247a5">&nbsp;</div>
                

                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($uri: String!) {
    mysqlItineraryAllData(slug: { eq: $uri }) {
      author_id
      cityId
      city_id
      city_slug
      city_title
      content
      createdAt
      id
      isDraft
      isPublished
      latitude
      longitude
      mysqlId
      slug
      stateId
      state_title
      state_slug
      title
      updatedAt
      zone_slug
      zone_title
      meta_description
      meta_keyword
      popular_collection
    }
    allMysqlCities {
      nodes {
        mysqlId
        title
        latitude
        longitude
        slug
        thumbImageData
        itineraries {
          title
          slug
        }
      }
    }
    allMysqlItineraryAllData {
      nodes {
        mysqlId
        slug
        content
        title
        city_id
        city_title
        stateId
        popular_collection
      }
    }
  }
`

const RelatedDestinations = iti => {
  const list = iti.data.map(i => {
    const image = JSON.parse(JSON.parse(i.content).primaryInfo.bannerImage)
    return (
      <a href={"/" + i.slug.toLowerCase()} title={"Explore " + i.title}>
        {" "}
        <div className="box-item">
          <div
            className="thumb"
            style={{
              backgroundImage: `url('${settings.IMAGE_URL + image.publicUrl}')`,
            }}
          ></div>
          {i.title}
        </div>
      </a>
    )
  })
  if (list.length > 0) {
    return (
      <div className="relatedItineraries">
        <h3>Related Destinations</h3>
        <div>{list}</div>
      </div>
    )
  }
  return <div></div>
}

const NearbyDestinations = nearbyCities => {
  const list = nearbyCities.data.map(city => {
    if (city.thumbImageData !== "" && city.itineraries.length > 0) {
      const image = JSON.parse(city.thumbImageData)
      let url = "/" + city.slug.toLowerCase()
      if (city.itineraries.length == 1) {
        url = "/" + city.itineraries[0].slug.toLowerCase()
      }
      return (
        <a href={url} title={"Explore " + city.title}>
          {" "}
          <div className="box-item" key={"nearby_City" + city.mysqlid}>
            <div
              className="thumb"
              style={{
                backgroundImage: `url('${
                  settings.IMAGE_URL + image.thumbUrl + ".webp"
                }')`,
              }}
            ></div>
            {city.title}
          </div>
        </a>
      )
    }
  })
  if (list.length > 0) {
    return (
      <div className="nearbycities">
        <h3>Nearby Destinations</h3>
        <div>{list}</div>
      </div>
    )
  }
  return <div></div>
}

const ContentBlock = ({
  data,
  numberOfDays,
  showFullContent,
  setShowFullContent,
  confirmationMail,
  setConfirmationMail,
  itemsAfterDayWiseSection,
}) => {
  const classes = `contentblock ${
    itemsAfterDayWiseSection ? "itemsAfterItinerary" : ""
  }`

  return (
    <div className={classes}>
      <h2>{data.heading}</h2>
      {ITIN_PAYMENT_FEATURE && !showFullContent && itemsAfterDayWiseSection ? (
        <ModalComponent
          numberOfDays={numberOfDays}
          showFullContent={showFullContent}
          setShowFullContent={setShowFullContent}
          confirmationMail={confirmationMail}
          setConfirmationMail={setConfirmationMail}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      )}
    </div>
  )
}
const Caption = data => {
  const image = data.image
  return (
    <div className="row">
      <div className="col">
        <p>
          <i>{image.caption}</i>
        </p>
      </div>
      {(image.ownerUrl || image.ownerName) && (
        <div className="col">
          <p className="attribute" style={{ textAlign: "right" }}>
            <i>
              {image.ownerUrl && (
                <a href={image.ownerUrl} target="_blank">
                  &copy; {image.ownerName}
                </a>
              )}
              {!image.ownerUrl && <p>&copy; {image.ownerName}</p>}
            </i>
          </p>
        </div>
      )}
    </div>
  )
}

const Day = ({
  data,
  index,
  numberOfDays,
  showFullContent,
  setShowFullContent,
  confirmationMail,
  setConfirmationMail,
}) => {
  // const collapse = (e)=>{
  //   const id = e.currentTarget.getAttribute("data-id")
  //   document.querySelector(`.activityWrapper[data-id="${id}"]`).classList.toggle("collapse");

  //   //document.querySelector('')
  // }
  const id = v4()
  // console.log(index)
  return (
    <div className="day wrapper accordion">
      <details
        class="det"
        close={index > 0 ? true : false}
        open={!ITIN_PAYMENT_FEATURE && index === 0 ? true : false}
      >
        <summary>
          <h3 data-id={id}> {data.heading}</h3>
        </summary>
        {ITIN_PAYMENT_FEATURE && !showFullContent ? (
          <ModalComponent
            numberOfDays={numberOfDays}
            showFullContent={showFullContent}
            setShowFullContent={setShowFullContent}
            confirmationMail={confirmationMail}
            setConfirmationMail={setConfirmationMail}
          />
        ) : (
          <React.Fragment>
            <div className="activityWrapper" data-id={id}>
              {data.activities.map((activity, i) => {
                return (
                  <div className="activity" key={"day_activity_" + i}>
                    <SimpleReactLightbox>
                      <SRLWrapper
                        options={{
                          buttons: { showDownloadButton: false },
                        }}
                      >
                        <img
                          src={
                            settings.IMAGE_URL +
                            activity.icon.publicUrl +
                            ".webp"
                          }
                          className="icon"
                          aria-hidden="true"
                        />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                    <div
                      dangerouslySetInnerHTML={{ __html: activity.content }}
                    ></div>
                    <Row>
                      {activity.images.map((image, i) => {
                        return (
                          <SimpleReactLightbox>
                            <SRLWrapper
                              options={{
                                buttons: { showDownloadButton: false },
                              }}
                            >
                              <Col
                                className="activityImages"
                                key={"day_activity_images_" + i}
                                style={{ display: i > 0 ? "none" : "block" }}
                              >
                                <a
                                  href={
                                    settings.IMAGE_URL +
                                    image.publicUrl +
                                    ".webp"
                                  }
                                >
                                  <img
                                    src={
                                      settings.IMAGE_URL +
                                      image.publicUrl +
                                      ".webp"
                                    }
                                    alt={image.caption}
                                  />
                                  <SeeMore />
                                </a>
                                {!image.hideCaption && (
                                  <Caption image={image} />
                                )}
                              </Col>
                            </SRLWrapper>
                          </SimpleReactLightbox>
                        )
                      })}
                    </Row>
                  </div>
                )
              })}
            </div>
          </React.Fragment>
        )}
      </details>
    </div>
  )
}
const SeeMore = () => {
  return (
    <div
      className="btnSeeMore"
      onClick={e => {
        e.preventDefault()
        e.currentTarget.parentElement.getElementsByTagName("img")[0].click()
      }}
    >
      See More
    </div>
  )
}

const DaywiseSchedule = ({
  data,
  showFullContent,
  setShowFullContent,
  confirmationMail,
  setConfirmationMail,
}) => {
  return (
    <div className="daywiseSchedule">
      <h2>Itinerary</h2>
      <div className="content">
        {data.schedule.map((day, i) => {
          return (
            <Day
              data={day}
              key={i}
              index={i}
              numberOfDays={data.schedule.length}
              showFullContent={showFullContent}
              setShowFullContent={setShowFullContent}
              confirmationMail={confirmationMail}
              setConfirmationMail={setConfirmationMail}
            />
          )
        })}
      </div>
    </div>
  )
}

const CreateComment = ({ contentID }) => {
  const [comment, setComment] = useState()
  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const postComment = e => {
    e.preventDefault()
    axios({
      method: "post",
      url: settings.API_URL + "api/comment",
      data: {
        comment: comment,
        name: name,
        email: email,
        url: window.location.href,
        content_id: contentID,
      },
    })
      .then(data => {
        if (data.data.status === "success") {
          alert(
            "thank you for adding your comment. It will be visible on site after review"
          )
          setComment("")
          setEmail("")
          setName("")
        } else {
          throw "error"
        }
      })
      .catch(err => {
        alert(err)
      })
  }
  const onSubmit = function (e) {
    console.log(e)
  }
  return (
    <form onSubmit={postComment}>
      <Row className="g-3 commentbox">
        <Col lg={6}>
          <input
            type="text"
            name="name"
            placeholder="your name"
            onChange={e => setName(e.currentTarget.value)}
            className="form-control"
            value={name}
          />
        </Col>
        <Col lg={6}>
          <input
            type="email"
            name="email"
            placeholder="email"
            className="form-control"
            onChange={e => setEmail(e.currentTarget.value)}
            value={email}
          />
        </Col>
        <Col lg={12}>
          <textarea
            placeholder="comment"
            onChange={e => setComment(e.currentTarget.value)}
            className="form-control"
            value={comment}
          ></textarea>
        </Col>
        <Col lg={3}>
          <input
            type="submit"
            className="btn btnAskNow"
            value={"Comment"}
          ></input>
        </Col>
      </Row>
    </form>
  )
}

const Comments = ({ contentID }) => {
  const [comments, setComments] = useState([])

  useEffect(() => {
    getComments()
  }, [])
  const getComments = async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: settings.API_URL + "api/comments/" + contentID,
      })
      setComments(data.data)
    } catch (e) {
      console.log(e)
    }
  }

  const commentHTML = []
  if (Object.keys(comments).length > 0) {
    for (const key in comments) {
      const threadComments = comments[key]
      for (let i = 0; i < threadComments.length; i++) {
        commentHTML.push(
          <div
            className={threadComments[i].is_reply ? "reply comment" : "comment"}
          >
            <div className="profilepic">
              {threadComments[i].name.substr(0, 1).toUpperCase()}
            </div>
            <div>
              <span>{threadComments[i].name}</span>
              <p>{threadComments[i].comment}</p>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <CreateComment contentID={contentID} />
      <div className="comments">{commentHTML}</div>
    </div>
  )
}

const RatingSection = () => {
  return (
    <div className="rating">
      <p>Was this itinerary helpful?</p>
      <Row>
        <Col>
          <span className="icon-rating"></span>
        </Col>
        <Col>
          <span className="icon-rating"></span>
        </Col>
        <Col>
          <span className="icon-rating"></span>
        </Col>
        <Col>
          <span className="icon-rating"></span>
        </Col>
        <Col>
          <span className="icon-rating"></span>
        </Col>
      </Row>
    </div>
  )
}

function ImageBlock({ data }) {
  return (
    <Row className="image-block">
      {data.images.map((image, i) => {
        return (
          <SimpleReactLightbox>
            <SRLWrapper
              options={{
                buttons: { showDownloadButton: false },
              }}
            >
              <Col key={"image_" + i}>
                <img
                  src={settings.IMAGE_URL + image.imageData.publicUrl + ".webp"}
                  alt={image.imageData.caption}
                />
                <SeeMore></SeeMore>
                {!image.imageData.hideCaption && (
                  <Caption image={image.imageData} />
                )}
              </Col>
            </SRLWrapper>
          </SimpleReactLightbox>
        )
      })}
    </Row>
  )
}

const ModalComponent = ({
  numberOfDays,
  showFullContent,
  setShowFullContent,
  confirmationMail,
  setConfirmationMail,
}) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleOpen = () => {
    const timer =  setTimeout(() => {
      setModal(true)
     }, 60000)
      return () => clearTimeout(timer);
 }
 useEffect(() => {
  handleOpen();
}, [])

 
  const verifyPayment = (response, email, receipt) => {
    setShowFullContent(true)

    axios({
      method: "post",
      url: settings.API_URL + "api/payment/verify",
      data: {
        response: response,
        email: email,
        url: window.location.href,
        receipt: receipt,
      },
    })
      .then(data => {
        if (data.data.signatureIsValid && data.data.mailSent) {
          // alert(
          //   "payment signature is verified and email sent"
          // )
          setConfirmationMail(email)
          setModal(true)
        } else {
          setConfirmationMail("error")
          throw "error"
        }
      })
      .catch(err => {
        alert(err)
      })
  }

  const handleSubmit = e => {
    setModal(!modal)
    e.preventDefault()
    axios({
      method: "post",
      url: settings.API_URL + "api/payment",
      data: {
        numberOfDays: numberOfDays, 
        couponCode: e.target.ccode.value      
      },
    })
      .then(data => {
       
         if (data.status === 200) {
           const options = {
             key: "rzp_live_35rzAnSDzKlzAC",
             currency: data.data.currency,
             amount: data.data.amount,
             name: "https://iwant2explore.com",
             description: "Itinerary Purchase",
             order_id: data.data.id,
             notes: {
               itinerary: window.location.href,
               email: e.target.email.value,
               couponCode: e.target.ccode.value
             },
             handler: function (response) {
               verifyPayment(response, e.target.email.value, data.data.receipt)
             },
             prefill: {
               email: e.target.email.value,
             },
           }
           const paymentObject = new window.Razorpay(options)
           paymentObject.open()
           paymentObject.on("payment.failed", function (response) {
             alert(response.error.code)
             alert(response.error.description)
             alert(response.error.source)
             alert(response.error.step)
             alert(response.error.reason)
             alert(response.error.metadata.order_id)
             alert(response.error.metadata.payment_id)
           })
         } else {
           throw "error"
         }
      })
      .catch(err => {
        alert(err)
      })
  }

  const ConfirmationMessage = ({ toggle, confirmationMail }) => {
    const handleClick = () => {
      toggle()
      setConfirmationMail("")
    }
    const isError = confirmationMail === "error"
    return (
      <Modal isOpen={true} toggle={toggle}>
        {isError ? (
          <div className="confirmation">
            <span>
              Sorry Unable to send email, you will receive the refund in couple
              of working days
            </span>
            <br />
            <button onClick={handleClick} className="subscribeBtn">
              Ok
            </button>
          </div>
        ) : (
          <div className="confirmation">
            <span>
              Thanks for the payment! Your itinerary will be emailed to you in next 15 min. If you did not receive any mail then please contact us at visa2explore@gmail.com
            </span>
            <br />
            <span>Happy travelling!!!</span>
            <br />
            <button onClick={handleClick} className="subscribeBtn">
              Ok
            </button>
          </div>
        )}
      </Modal>
    )
  }
 
}
